import { useNavigate } from 'react-router-dom';
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import { useLocation } from 'react-router-dom'
import ValetComponent from "../../Components/valet";
import Input from "../../Components/input";

function Payment() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNext = () => {
        navigate('/payment-success');
    }

    return (
        <SiteWrap loading={false} className='payment' title={'Payment'}>
            {location.state.data && <ValetComponent data={location.state.data} />}

            {location.state.data && <div className="payment-card">
                <Input label={'Card Number'} cardNumber placeholder={'1234 1234 1234 1234'} />
                <div className="d-flex justify-content-between">
                    <Input label="Expiration" placeholder="MM/YY" />
                    <div className="space" />
                    <Input label="CVC" placeholder="CVC" />
                </div>
                <div className="d-flex justify-content-between">
                    <Input label="Country" placeholder="United Kingdom" />
                    <div className="space" />
                    <Input label="Postal Code" placeholder="Postal Code" />
                </div>
            </div>}

            {location.state.data && <div className='footer justify-content-center'>
                <Button title="Pay Now" lg onClick={handleNext} />
            </div>}

        </SiteWrap>
    )
}
export default Payment;