import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Valets } from "./content";
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import ValetComponent from "../../Components/valet";

function ValetOption() {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState(null)
    
    const handleBack = () => {
        navigate(-1);
    }

    const handleNext = () => {
        navigate('/payment', {
            state: {
                data: selectedItem
            }
        })
    }

    return (
        <SiteWrap loading={false} className='valet' title={'Would you like your Mercedes to be professionally valeted while you’re away?'} maxWidth={616}>
            <div className="valet-wrap">
                {
                    Valets.map(v => (
                        <ValetComponent onClick={() => setSelectedItem(v)} selected={v.title === selectedItem?.title} key={v.title} data={v} />
                    ))
                }
            </div>


            <div className="no-thanks" onClick={()=>navigate('/phone-number')}>No Thanks</div>

            <div className='footer'>
                <Button title="Back" onClick={handleBack} back />
                <Button title="Next" onClick={handleNext} disable={!selectedItem}/>
            </div>

        </SiteWrap>
    )
}
export default ValetOption;