import { forwardRef, useEffect, useState } from 'react';
import Footer from './footer';
import './style.scss';
import Logo from '../Assets/logo.png';
import Logo1 from '../Assets/logo1.svg';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const SiteWrap = forwardRef(({ loading, className, title, handleHideCalendar, bigLogo, des, maxWidth, firstPage, children }, ref) => {
    const navigate = useNavigate();
    const [back, setBack] = useState(false);
    const { data } = useSelector((state) => state.data);
    useEffect(() => {

        const checkIfClickedOutside = e => {
            if (ref && ref.current && !ref.current.contains(e.target)) {
                handleHideCalendar()
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    });
    const brand = localStorage.getItem('brand') ?? "APX";
    useEffect(() => {
        if (!data.clientCode && !firstPage) {
            navigate('/');
        }
    }, []);

    return (
        <motion.div
            className={brand === 'HX' ? 'site-wrap hx' : 'site-wrap'}
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: back ? 200 : -200, opacity: 0, transition: { duration: 0.1 } }}
        >
            {loading && (<div className='loading'>
                <div className="spinner-border text-warning" role="status" />
            </div>
            )}
            <div className={`site-wrap-header${bigLogo ? ' bigLogo' : ''}`}>
                <img src={brand === 'HX' ? Logo1 : Logo} />
                {title && <div className='title' style={{ maxWidth: maxWidth ? maxWidth : "unset" }}>{title}</div>}
                {des && <div className='des'>{des}</div>}
            </div>
            <div className={className}>
                {children}
            </div>
            <div>
                <Footer />
            </div>
        </motion.div>
    )
})
export default SiteWrap;