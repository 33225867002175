import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import Car from "../../Assets/Car.svg";
import Car1 from "../../Assets/Car1.svg";
import { setData } from "../../store/reducers/dataAction";

function CarReg() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.data);
    const [vehicle, setVehicle] = useState('');
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [edit, setEdit] = useState(false);
    const handleChange = (e) => {
        setVehicle((e.target.value).toUpperCase());
        setError("");
    }
    const handleSearch = () => {
        setError("");
        const bodyData = {
            registration: vehicle.replace(/ /g, "")
        };
        setLoading(true)
        axios.post('https://pk-check-in-api.dock-yard.io/v1/getVehicleByRegistration', bodyData)
            .then(res => {
                if (res.status === 200) {
                    setResult({ vehicleColor: res.data.colour, vehicleModel: res.data.model, vehicleMake: res.data.make });
                }
                setLoading(false);
            })
            .catch(e => {
                setError(e.message)
                setLoading(false);
                if(e.message.includes('code 404')) {
                  dispatch(setData({ vehicle: vehicle }));
                  navigate('/newreference');
                }
            });
    }
    
    useEffect(() => {
        if (data.vehicle) {
            setVehicle(data.vehicle);
        }
        if (data.vehicleColor && data.vehicleModel) {
            setResult({ vehicleColor: data.vehicleColor, vehicleModel: data.vehicleModel, vehicleMake: data.vehicleMake })
        }
    }, [])
    const handleNext = () => {
        if (data.vehicle !== vehicle) {
            dispatch(setData({ vehicle, ...result, change: true, vehicle: vehicle }))
        } else {
            dispatch(setData({ vehicle, ...result, vehicle: vehicle }))
        }

        navigate('/passengers')
    }
    const handleNo = () => {
        setVehicle('');
        setResult(null);
        setEdit(true);
    }
    const brand = localStorage.getItem('brand') ?? "APX"
    return (
        <SiteWrap loading={loading} className='car-reg' title={result ? 'Is this the car you will be parking?' : 'Enter your vehicle registration below'}>
            <div className={result ? "reg-wrap result" : "reg-wrap"}>
                <input
                    value={vehicle.toUpperCase()}
                    type="text"
                    placeholder="Enter Reg"
                    onChange={handleChange}
                    disabled={!edit}
                />
                {
                  error && <div className="result-error">{error}</div>
                }
                {
                    result &&
                    <div className="search-result">
                        <img src={brand === 'HX' ? Car1 : Car} className='car' />
                        <span>{result.vehicleMake} {result.vehicleModel} {result.vehicleColor}</span>
                    </div>
                }
            </div>
            {result ?
                <div className='footer'>
                    <Button title="No" onClick={handleNo} style={{ backgroundColor: '#fff' }} />
                    <Button title="Yes" onClick={handleNext} />
                </div> :
                <div className='footer justify-content-center'>
                    <Button title="Search" lg disable={!vehicle} onClick={handleSearch} />
                </div>
            }
        </SiteWrap>
    )
}
export default CarReg;