import Lottie from 'react-lottie';
import { useNavigate, useSearchParams } from 'react-router-dom'
import SiteWrap from "../../Components/sitewrap";
import introAnimation from '../../Assets/lottie/Intro.json';
import introAnimation1 from '../../Assets/lottie/Intro1.json';
import { useDispatch } from 'react-redux';
import './style.scss';
import Button from '../../Components/button';
import { useEffect, useState } from 'react';
import { setData } from '../../store/reducers/dataAction';
import moment from 'moment';
import axios from 'axios';
const defaultOptions1 = {
    loop: true,
    animationData: introAnimation1,
    autoplay: true,
};
const defaultOptions = {
    loop: true,
    animationData: introAnimation,
    autoplay: true,
};
function Intro() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [name, setName] = useState('');
    const [arrival, setArrival] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const params = Object.fromEntries([...searchParams]);
        setName(params?.name);
        setArrival(params?.arrival);
        let storeData = {};
        if (params.name) {
            storeData.name = params.name;
        }
        if (params.arrival) {
            storeData.arrival = params.arrival;
        }
        if (params['car-reg']) {
            storeData.vehicle = params['car-reg'];
        }
        if (params.pax) {
            storeData.pax = params.pax;
        }
        if (params.airline) {
            storeData.airline = params.airline;
        }
        if (params.from) {
            storeData.from = params.from;
        }
        if (params.phone) {
            storeData.phone = params.phone;
        }
        if (params.brand) {
            localStorage.setItem('brand', params.brand);
        }
        if (params.clientCode && params.operationCode && params.reference) {
            getBookingRef(params);
        }
        if (params['return-date']) {
            storeData.returnDate = params['return-date'];
        }
        if (params['landing-time']) {
            storeData.landingTime = params['landing-time'];
        }
        if (params['flight-number']) {
            storeData.flightNumber = params['flight-number'];
        }
        dispatch(setData(storeData));
    }, []);
    const next = () => {
        navigate('/car-registration');
    }
    const getBookingRef = (data) => {
        const bodyData = {
            "operationId": {
                "clientCode": data.clientCode,
                "operationCode": data.operationCode
            },
            "reference": data.reference
        }
        setLoading(true);
        axios.post('https://pk-check-in-api.dock-yard.io/v1/getBookingByRef', bodyData)
            .then(res => {
                console.log(res.status, res.data);
                if (res.status === 200) {
                    const storeData = {
                        pax: res.data?.passengerCount,
                        phone: (res.data?.leadContact?.phone).replace('+', ''),
                        vehicle: res.data?.vehicle?.registration,
                        flightNumber: res.data?.flights?.return?.flightNumber,
                        landingTime: moment(res.data?.flights?.return?.arrival?.scheduledAt).format('HH:mm'),
                        returnDate: moment(res.data?.flights?.return?.arrival?.scheduledAt).format('YYYY-MM-DD'),
                        airline: res.data?.flights?.return?.airline?.iata,
                        from: res.data?.flights?.return?.departure?.airport?.city + ', ' + res.data?.flights?.return?.departure?.airport?.country,
                        fullData: res.data,
                        vehicleColor: res.data?.vehicle?.colour,
                        vehicleModel: res.data?.vehicle?.model,
                        vehicleMake: res.data?.vehicle?.make,
                        clientCode: data.clientCode,
                        operationCode: data.operationCode,
                        reference: data.reference,
                        change: false
                    }
                    setName(res.data?.leadContact?.name.firstName + ' ' + res.data?.leadContact?.name.lastName);
                    dispatch(setData(storeData));
                    setArrival(res.data?.flights?.return?.arrival?.scheduledAt);
                }
                setLoading(false);
            })
            .catch(e => {
                console.log(e.message);
                setLoading(false);
            });
    }
    const brand = localStorage.getItem('brand') ?? "APX"
    return (
        <SiteWrap loading={loading} className='intro' bigLogo firstPage >
            <div className='intro-animation'>
                <Lottie options={brand === "HX" ? defaultOptions1 : defaultOptions} />
            </div>
            <div>
                <div className='intro-title'>Hi {name}</div>
                <div className='intro-description'>
                    We’re looking forward to welcoming you at Airparks on {moment(arrival).format('dddd')}.
                </div>
                <div className='intro-description'>
                    For the smoothest experience when you arrive, we’d like to check a few details with you now.<br /><br />
                </div>
            </div>
            <div className='footer justify-content-center'>
                <Button title="Start Check-in" lg onClick={next} />
            </div>
        </SiteWrap>
    )
}
export default Intro;