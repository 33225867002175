import React from "react";
import { Route, Routes, useLocation } from 'react-router-dom'
import CarReg from '../Pages/carReg';
import Intro from '../Pages/intro';
import Passengers from '../Pages/passengers';
import Flight from '../Pages/flight';
import ReturningFrom from '../Pages/flight/returningFrom';
import FlightTo from '../Pages/flight/flightTo';
import FlightDetails from '../Pages/flight/details';
import ValetOption from '../Pages/valet';
import Payment from '../Pages/payment';
import PaymentSuccess from '../Pages/payment/success';
import PhoneNumber from '../Pages/payment/mobileNumber';

import { AnimatePresence } from 'framer-motion';
import NewReference from "../Pages/carReg/newDetail";
function AnimationRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Intro />} />
                <Route path='/car-registration' element={<CarReg />} />
                <Route path='/newreference' element={<NewReference />} />
                <Route path='/passengers' element={<Passengers />} />
                <Route path='/flight' element={<Flight />} />
                <Route path='/returning-from' element={<ReturningFrom />} />
                <Route path='/returning-to' element={<FlightTo />} />
                <Route path='/flight-details' element={<FlightDetails />} />
                <Route path='/valet-options' element={<ValetOption />} />
                <Route path='/payment' element={<Payment />} />
                <Route path='/payment-success' element={<PaymentSuccess />} />
                <Route path='/phone-number' element={<PhoneNumber />} />
                <Route path='/all-set' element={<PaymentSuccess />} />
            </Routes>
        </AnimatePresence>
    )
};

export default AnimationRoutes;