import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import CalendarIcon from '../../Assets/CalendarBlank.svg';
import CalendarIcon1 from '../../Assets/CalendarBlank1.svg';
import RightArrow from '../../Assets/rightArrow.svg';
import LeftArrow from '../../Assets/leftArrow.svg';
import RightArrow1 from '../../Assets/rightArrow1.svg';
import LeftArrow1 from '../../Assets/leftArrow1.svg';
import Ticket from "../../Components/ticket";
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../store/reducers/dataAction";
import axios from "axios";
import BA from "../../Assets/flightLogo/British_big.svg";

function Flight() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.data);
    const [value, setValue] = useState(new Date(data.returnDate));
    const [loading, setLoading] = useState(false);
    const calendarRef = useRef();
    const [showCalendar, setShowCalendar] = useState(false);
    const [flightList, setFlightList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const brand = localStorage.getItem('brand') ?? 'APX';
    const handleChange = (e) => {
        setValue(e);
        setShowCalendar(false);
    }
    const handleBack = () => {
        navigate(-1);
    }

    const handleNext = (item) => {
        // if (data.airline !== selectedItem?.logo) {
        //     dispatch(setData({ airline: selectedItem?.logo, returDate: moment(value).format('YYYY-MM-DD'), change: true }));
        // } else {
        //     dispatch(setData({ airline: selectedItem?.title, returDate: moment(value).format('YYYY-MM-DD'), }));
        // }
        // navigate('/returning-from', {
        //     state: {
        //         data: selectedItem?.data,
        //         date: value
        //     }
        // })
        setSelectedItem(item);
        if (data.airline !== item?.logo) {
            dispatch(setData({ airline: item?.logo, returDate: moment(value).format('YYYY-MM-DD'), change: true }));
        } else {
            dispatch(setData({ airline: item?.title, returDate: moment(value).format('YYYY-MM-DD'), }));
        }
        navigate('/returning-from', {
            state: {
                data: item?.data,
                date: value
            }
        })
    }
    useEffect(() => {
        getFlightsData();
    }, [value]);

    const getFlightsData = () => {
        const bodyData = {
            operationId: {
                clientCode: data.clientCode,
                operationCode: data.operationCode
            },
            date: moment(value).format("YYYY-MM-DD")
        };
        setLoading(true);
        axios.post('https://pk-check-in-api.dock-yard.io/v1/getFlightsByDate', bodyData)
            .then(res => {
                if (res.status === 200) {
                    const groupedData = res.data.reduce((result, item) => {
                        const category = item.airline.iata;
                        if (!result[category]) {
                            result[category] = [];
                        }
                        result[category].push(item);
                        return result;
                    }, {});
                    let array = [];
                    for (const key in groupedData) {
                        let obj = {}
                        obj.logo = key;
                        obj.data = groupedData[key];
                        array.push(obj);
                    }
                    setFlightList(array);
                }
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
            });
    }

    const renderLogo = (airline) => {
        try {
            if (airline === "BA") return BA;
            return require(`../../Assets/Logos/${airline}.png`);
        } catch (err) {
            console.log(err);
            return null;
        }
    }


    return (
        <SiteWrap
            ref={calendarRef}
            loading={loading}
            className='flight'
            title={'Which airline are you flying home with?'}
            handleHideCalendar={() => setShowCalendar(false)}
        >
            <div className="d-flex align-items-center w-100 flight-content">
                <div className="returning-text">Returning On</div>
                <div className="flight-date" ref={calendarRef}>
                    <div className="flight-date-area"
                        style={{ border: showCalendar ? brand === "HX" ? '1px solid #FDDC06' : '1px solid #00FFFF' : 'none' }}
                        onClick={() => setShowCalendar(!showCalendar)}
                    >
                        <img src={brand === "HX" ? CalendarIcon1 : CalendarIcon} />
                        <div>{moment(value).format('DD/MM/YYYY')}</div>
                    </div>
                    {showCalendar &&
                        <Calendar
                            onChange={(e) => handleChange(e)}
                            value={value}
                            nextLabel={<img src={brand === "HX" ? RightArrow1 : RightArrow} />}
                            prevLabel={<img src={brand === "HX" ? LeftArrow1 : LeftArrow} />}
                        />
                    }
                </div>
            </div>
            {
                flightList.map(c => (
                    <Ticket key={c.logo} onClick={() => {handleNext(c);}} selected={c.logo === selectedItem?.logo}>
                        <img src={renderLogo(c.logo)} />
                    </Ticket>
                ))
            }
            <div className='footer'>
                <Button title="Back" onClick={handleBack} back />
                <Button title="Next" onClick={handleNext} disable={!selectedItem} />
            </div>

        </SiteWrap>
    )
}
export default Flight;