import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import p1 from '../../Assets/passenger1.svg';
import p2 from '../../Assets/passenger2.svg';
import p3 from '../../Assets/passenger3.svg';
import p4 from '../../Assets/passenger4.svg';
import p5 from '../../Assets/passenger5.svg';
import p6 from '../../Assets/passenger6.svg';
import p7 from '../../Assets/passenger7.svg';
import p8 from '../../Assets/passenger8.svg';
import p11 from '../../Assets/passenger11.svg';
import p21 from '../../Assets/passenger21.svg';
import p31 from '../../Assets/passenger31.svg';
import p41 from '../../Assets/passenger41.svg';
import p51 from '../../Assets/passenger51.svg';
import p61 from '../../Assets/passenger61.svg';
import p71 from '../../Assets/passenger71.svg';
import p81 from '../../Assets/passenger81.svg';
import Minus from '../../Assets/minus.svg';
import Plus from '../../Assets/plus.svg';
import Minus1 from '../../Assets/minus1.svg';
import Plus1 from '../../Assets/plus1.svg';
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../store/reducers/dataAction";

function Passengers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.data);
    const [passengers, setPassengers] = useState(1);
    const brand = localStorage.getItem('brand') ?? 'APX';
    useEffect(() => {
        if (data.pax)
            setPassengers(Number(data.pax));
    }, [])
    const handleBack = () => {
        navigate(-1);
    }

    const handleNext = () => {
        if (Number(data.pax) !== Number(passengers)) {
            dispatch(setData({ pax: passengers, change: true }));
        } else {
            dispatch(setData({ pax: passengers }));
        }
        navigate('/flight-details')
    }
    const renderPassengerIcon = () => {
        if (brand === "HX") {
            switch (passengers) {
                case 1:
                    return <img src={p11} />
                case 2:
                    return <img src={p21} />
                case 3:
                    return <img src={p31} />
                case 4:
                    return <img src={p41} />
                case 5:
                    return <img src={p51} />
                case 6:
                    return <img src={p61} />
                case 7:
                    return <img src={p71} />
                case 8:
                    return <img src={p81} />
            }
        } else {
            switch (passengers) {
                case 1:
                    return <img src={p1} />
                case 2:
                    return <img src={p2} />
                case 3:
                    return <img src={p3} />
                case 4:
                    return <img src={p4} />
                case 5:
                    return <img src={p5} />
                case 6:
                    return <img src={p6} />
                case 7:
                    return <img src={p7} />
                case 8:
                    return <img src={p8} />
            }
        }


    }
    const handleMinus = () => {
        if (passengers === 1) return;
        setPassengers(passengers - 1);
    }
    const handlePlus = () => {
        if (passengers === 8) return;
        setPassengers(passengers + 1);
    }
    return (
        <SiteWrap loading={false} className='passengers' title={'How many people will be travelling in your vehicle including yourself?'} maxWidth={550}>
            <div className="passengers-count">
                {renderPassengerIcon()}
                <div className="d-flex w-100 align-items-center justify-content-between">
                    {/* <div className={passengers === 1 ? "passengers-count-minus disable" : "passengers-count-minus"} onClick={handleMinus} /> */}
                    <img src={brand === "HX" ? Minus1 : Minus} style={{ opacity: passengers === 1 ? 0.5 : 1 }} onClick={handleMinus} />
                    <div className="passengers-count-text">{passengers} {passengers === 1 ? "Person" : passengers === 8 ? "People+" : "People"}</div>
                    <img src={brand === "HX" ? Plus1 : Plus} style={{ opacity: passengers === 8 ? 0.5 : 1 }} onClick={handlePlus} />
                    {/* <div className={passengers === 8 ? "passengers-count-plus disable" : "passengers-count-plus"} onClick={handlePlus} /> */}
                </div>
            </div>
            <div className='footer'>
                <Button title="Back" onClick={handleBack} back />
                <Button title="Next" onClick={handleNext} />
            </div>

        </SiteWrap>
    )
}
export default Passengers;