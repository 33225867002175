import Master from '../Assets/master.svg';
import Visa from '../Assets/visa.svg';
import { Country } from './country';
const Input = ({ label, value, type, placeholder, cardNumber, className, ...rest }) => (

    <div className={`input${className ? ` ${className}` : ''}`}>
        {label && <label>{label}</label>}
        <div className='input-field'>
            {label !== "Country" ?
                <input type={type} value={value} placeholder={placeholder} {...rest} className="custom-input" /> :
                <select className='custom-select'>
                    {
                        Country.map(c => <option value={c.code} selected={c.code === "GB"}>{c.name}</option>)
                    }
                </select>
            }
            {cardNumber &&
                <div className='d-flex'>
                    <img src={Master} />
                    <img src={Visa} />
                </div>
            }
        </div>

    </div>
);
export default Input;