import Gold from '../../Assets/gold.svg';
import Silver from '../../Assets/silver.svg';
import Bronze from '../../Assets/bronze.svg';

export const Valets = [
    {
        icon: Gold,
        title: 'Gold',
        price: '£99'
    },
    {
        icon: Silver,
        title: 'Silver',
        price: '£49'
    },
    {
        icon: Bronze,
        title: 'Bronze',
        price: '£29'
    },
]