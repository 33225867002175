import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import Ticket from "../../Components/ticket";
import Arrow from '../../Assets/arrow.svg';
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../store/reducers/dataAction";
import moment from "moment";
import BA from "../../Assets/flightLogo/British_big.svg";

function FlightTo() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.data);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    
    const handleBack = () => {
        navigate(-1);
    }

    const handleNext = (item) => {
        // let flightData = { ...data.fullData };
        // if (selectedItem.flightNumber) {
        //     flightData.flights.return.arrival = selectedItem.arrival;
        //     flightData.flights.return.departure = selectedItem.departure;
        //     flightData.flights.return.flightNumber = selectedItem.flightNumber;
        //     dispatch(setData({ fullData: flightData }))
        // }
        // navigate('/valet-options');
        setSelectedItem(item)
        let flightData = { ...data.fullData };
        if (item.flightNumber) {
            flightData.flights.return.arrival = item.arrival;
            flightData.flights.return.departure = item.departure;
            flightData.flights.return.flightNumber = item.flightNumber;
            dispatch(setData({ fullData: flightData }))
        }
        navigate('/valet-options');
    }
    const renderLogo = (airline) => {
        try {
            if (airline === "BA") return BA;
            return require(`../../Assets/Logos/${airline}.png`);
        } catch (err) {
            console.log(err);
            return null;
        }
    }
    return (
        <SiteWrap loading={loading} className='flight' title={'Select your flight'}>
            {
                location.state.data.map(c => (
                    <Ticket key={c.flightNumber} onClick={() => handleNext(c)} selected={c.flightNumber === selectedItem?.flightNumber} big>
                        <div className="flight-fromItem">
                            <img src={renderLogo(c.airline.iata)} className="flight-fromItem-logo logo3" />
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-1 time-row">
                                <span className="flight-fromItem-title">{c.departure.airport.iata}</span>
                                <div className="d-flex align-items-center">
                                    <img src={Arrow} style={{ marginRight: 22 }} />
                                    <span className="flight-fromItem-title">{moment(c.arrival.scheduledAt).format("HH:mm")}</span>
                                    <img src={Arrow} style={{ marginLeft: 30 }} />
                                </div>
                                <span className="flight-fromItem-title">{c.departure.airport.iata}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="flight-fromItem-location">{c.departure.airport.city}, {c.departure.airport.country}</span>
                                <span className="flight-fromItem-location second-location">{c.departure.airport.city}, {c.departure.airport.country}</span>
                            </div>
                        </div>
                    </Ticket>
                ))
            }
            <div className='footer'>
                <Button title="Back" onClick={handleBack} back />
                <Button title="Next" onClick={handleNext} disable={!selectedItem} />
            </div>

        </SiteWrap>
    )
}
export default FlightTo;