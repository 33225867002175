import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Lottie from "react-lottie";
import moment from "moment";
import { useSelector } from "react-redux";
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import Success from '../../Assets/lottie/recommended.json';
import Success1 from '../../Assets/lottie/recommended1.json';
import GlassSmile from '../../Assets/lottie/sun.json';
import GlassSmile1 from '../../Assets/lottie/sun1.json';
const defaultOptions = {
    loop: true,
    animationData: Success,
    autoplay: true,
};
const defaultOptions1 = {
    loop: true,
    animationData: Success1,
    autoplay: true,
};
const setOptions = {
    loop: true,
    animationData: GlassSmile,
    autoplay: true,
};
const setOptions1 = {
    loop: true,
    animationData: GlassSmile1,
    autoplay: true,
};
function PaymentSuccess() {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = useSelector((state) => state.data);
    const allset = location.pathname.includes('all-set');
    const [selectedItem, setSelectedItem] = useState(null)
    const handleChange = (e) => {
    }
    const handleBack = () => {
        navigate(-1);
    }

    const handleNext = () => {
        navigate('/phone-number');
    }

    const brand = localStorage.getItem("brand") ?? "APX";

    return (
        <SiteWrap loading={false} className='payment' title={''}>

            {/* <img src={allset ? GlassSmile : Success} className="logo" /> */}
            {allset ?
                <div className='payment-animation'>
                    <Lottie options={brand === "HX" ? setOptions1 : setOptions} />
                </div> :
                <div className='payment-animation1'>
                    <Lottie options={brand === "HX" ? defaultOptions1 : defaultOptions} />
                </div>
            }
            <div className="successful">{allset ? 'All Set!' : 'Payment Successful'}</div>
            <div className="detail" style={allset ? { marginTop: 20 } : {}}>
                {allset ? `We have everything we need and look forward to seeing you on ${moment(data.returnDate).format('dddd')}.` : 'Your payment has been successfully processed.'}
            </div>
            {!allset && <div className="detail1">Please press next to confirm your contact information.</div>}
            {!allset &&
                <div className='footer justify-content-center'>
                    <Button title="Next" lg onClick={handleNext} />
                </div>
            }

        </SiteWrap>
    )
}
export default PaymentSuccess;