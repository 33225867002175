import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import SiteWrap from "../../Components/sitewrap";
import PhonePad from '../../Assets/phonepad.svg';
import PhonePad1 from '../../Assets/phonepad1.svg';
import './style.scss';
import Button from '../../Components/button';
import Input from "../../Components/input";
function PhoneNumber() {
    const navigate = useNavigate();
    const { data } = useSelector((state) => state.data);
    const [showPhone, setShowPhone] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('44 123456789');
    const [loading, setLoading] = useState(false);
    const brand = localStorage.getItem('brand') ?? "APX";
    const handleChange = (e) => {
    }
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (data.phone)
            setPhoneNumber(data?.phone);
    }, [])
    const handleBack = () => {
        setShowPhone(true);
    }

    const handleNext = () => {
        updateBooking()

    }
    const handleConfirm = () => {
        updateBooking()
    }
    function formatPhone(phoneNumber) {
        const formattedNumber = phoneNumber;

        if (formattedNumber.length > 0) {
            if (formattedNumber.length < 4) {
                return "(" + formattedNumber + "";
            }
            if (formattedNumber.length > 3 && formattedNumber.length < 7) {
                return `${formattedNumber.slice(0, 3)} ${formattedNumber.slice(3)}`;
            }
            if (formattedNumber.length > 6) {
                return `${formattedNumber.slice(0, 2)} ${formattedNumber.slice(
                    2,
                    6
                )} ${formattedNumber.slice(6, 9)} ${formattedNumber.slice(9, 12)}`;
            }
        }
        return formattedNumber;
    }
    const updateBooking = () => {
        setLoading(true);
        console.log(phoneNumber, data.phone);
        if (data.change || phoneNumber !== data.phone) {
            const bodyData = {
                "operationId": {
                    clientCode: data.clientCode,
                    operationCode: data.operationCode
                },
                "booking": {
                    "reference": data.reference,
                    "flights": {
                        "return": {
                            "airline": {
                                "iata": data.airline ?? data.fullData.flights.return?.departure?.airport?.iata
                            },
                            "flightNumber": data.flightNumber ?? data.fullData.flights.return?.flightNumber,
                            "departure": {
                                "airport": {
                                    "iata": data.fullData.flights.return?.departure?.airport?.iata,
                                    "name": data.fullData.flights.return?.departure?.airport?.name,
                                    "city": data.from.split(",")[0],
                                    "country": data.from.split(",")[1]
                                }
                            },
                            "arrival": {
                                "scheduledAt": new Date(data.returnDate)
                            }
                        }
                    },
                    "leadContact": {
                        "name": data.fullData?.leadContact?.name,
                        "phone": phoneNumber
                    },
                    "passengerCount": data.pax,
                    "vehicle": {
                        "registration": data.vehicle,
                        "make": data.vehicleMake,
                        "model": data.vehicleModel,
                        "colour": data.vehicleColor
                    }
                }
            };
            axios.post('https://pk-check-in-api.dock-yard.io/v1/updateBooking', bodyData)
                .then(res => {
                    navigate('/all-set')
                    setLoading(false);
                })
                .catch(e => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
            navigate('/all-set');
        }
    }

    return (
        <SiteWrap
            loading={loading} className='payment'
            title={showPhone ? 'Enter your mobile number' : 'Is this your mobile number?'}
            des={showPhone ? null : "When you arrive at site we will send instructions to you via SMS."}
        >
            {showPhone ?
                <Input pattern="[0-9]*" type="number" placeholder="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} /> :
                <div id='phone-pad'>
                    <div>+{formatPhone(phoneNumber)}</div>
                    <img src={brand === "HX" ? PhonePad1 : PhonePad} />
                </div>
            }
            {showPhone ?
                <div className='footer justify-content-center'>
                    <Button title="Confirm" lg onClick={handleConfirm} />
                </div> :
                <div className='footer'>
                    <Button title="No" onClick={handleBack} style={{ backgroundColor: '#fff' }} />
                    <Button title="Yes" onClick={handleNext} />
                </div>
            }

        </SiteWrap>
    )
}
export default PhoneNumber;