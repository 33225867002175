import { createBrowserRouter, RouterProvider, BrowserRouter as Router } from 'react-router-dom'
import './App.scss';

import AnimationRoutes from './Components/animatinoRoutes';

function App() {
  return (
    <Router>
      <AnimationRoutes/>
    </Router>
  );
}

export default App;
