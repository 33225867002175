import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import Plane from '../../Assets/plane.svg';
import moment from "moment";
import { setData } from "../../store/reducers/dataAction";
import BA from "../../Assets/flightLogo/British_big.svg";

function FlightDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.data);
    const [airline, setAirline] = useState('easyJet');
    const [from, setFrom] = useState('Tenerife, Spain');
    const [returnDate, setReturnDate] = useState(new Date());
    const [landingTime, setLandingTime] = useState('16:35');
    const [flightNumber, setFlightNumber] = useState('FLY123');
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (data.airline) {
            setAirline(data.airline);
        }
        if (data.from) {
            setFrom(data.from);
        }
        if (data.returnDate) {
            setReturnDate(data.returnDate);
        }
        if (data.landingTime) {
            setLandingTime(data.landingTime);
        }
        if (data.flightNumber) {
            setFlightNumber(data.flightNumber);
        }
    }, [])
    const handleBack = () => {
        navigate('/flight');
    }

    const handleNext = () => {
        dispatch(setData({airline, from, returnDate, landingTime, flightNumber}));
        navigate('/valet-options')
    }

    const renderLogo = () => {
        try {
            if (airline === "BA") return BA;
            return require(`../../Assets/Logos/${airline}.png`);
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    return (
        <SiteWrap loading={false} className='flight' title={'Are these details still correct?'}>
            <div className="flight-details">
                <div className="flight-details-wrap">
                    <div className="d-flex align-items-center justify-content-between">
                        <img src={renderLogo()} />
                        <img src={Plane} />
                    </div>
                    <div>
                        <div className="flight-details-wrap-from">Flying from</div>
                        <div className="flight-details-wrap-location">{from}</div>
                    </div>

                    <div className="flight-details-wrap-footer">
                        <div>
                            <div className="flight-details-wrap-from">Returning On</div>
                            <div className="flight-details-wrap-footer-date">{moment(returnDate).format('DD MMM YY')}</div>
                        </div>
                        <div>
                            <div className="flight-details-wrap-from">Landing at</div>
                            <div className="flight-details-wrap-footer-date">{landingTime}</div>
                        </div>
                        <div>
                            <div className="flight-details-wrap-from">Flight No</div>
                            <div className="flight-details-wrap-footer-date">{flightNumber}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <Button title="No" onClick={handleBack} style={{ backgroundColor: "#fff" }} />
                <Button title="Yes" onClick={handleNext} />
            </div>

        </SiteWrap>
    )
}
export default FlightDetails;