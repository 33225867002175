import { useNavigate } from 'react-router-dom';
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import { useLocation } from 'react-router-dom'
import ValetComponent from "../../Components/valet";
import Input from "../../Components/input";
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { setData } from '../../store/reducers/dataAction';

function NewReference() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = useSelector((state) => state.data);
  const [number, setNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [colour, setColour] = useState("");
  useEffect(() => {
    if (data?.vehicle) {
      setNumber(data.vehicle);
    }
  }, [data])

  const handleNext = () => {
    if (!number || !make || !model || !colour) {
      return;
    }
    dispatch(setData({ vehicle: number, vehicleColor: colour, vehicleMake: make, vehicleModel: model, change: true }));
    navigate('/passengers')
  }


  const handleNo = () => {
    navigate(-1);
  }
  return (
    <SiteWrap loading={false} className='car-reg' title={'Unfortunately we have been unable to find your vehicle. Please add the details below. '} maxWidth={550}>

      <div className={"new-reference"}>
        <Input placeholder={'Registration Number'} onChange={e => setNumber(e.target.value)} value={number} />
        <Input placeholder={'Vehicle Make'} onChange={e => setMake(e.target.value)} value={make} />
        <Input placeholder={'Vehicle Model'} onChange={e => setModel(e.target.value)} value={model} />
        <Input placeholder={'Vehicle Colour'} onChange={e => setColour(e.target.value)} value={colour} />

      </div>

      <div className='footer'>
        <Button title="Back" onClick={handleNo} style={{ backgroundColor: '#fff' }} back />
        <Button title="Next" onClick={handleNext} disable={!number || !make || !model || !colour} />
      </div>

    </SiteWrap>
  )
}
export default NewReference;