import Info from '../Assets/info.svg';

const ValetComponent = ({ data, selected, onClick }) => (
    <div className={`valet-component${selected ? ' selected' : ''} ${data.title}`} onClick={onClick}>
        <div className='d-flex align-items-center title-wrap'>
            <img src={data.icon} />
            <div className='title-text'>{data.title}</div>
            <img src={Info} />
        </div>
        <div className='price'>{data.price}</div>
    </div>
);

export default ValetComponent;