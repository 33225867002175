const Button = ({ title, style, disable, onClick, lg, back }) => (
    back ?
        <div style={style} onClick={disable ? null : onClick} className={lg ? "custom-button back long" : "custom-button back"}>
            <span className="arrow">{'<<'}</span>
            <span className="btn-title">{title}</span>
        </div> :
        <div
            onClick={disable ? null : onClick}
            className={lg ? disable ? "custom-button long disable" : "custom-button long" : disable ? "custom-button disable" : "custom-button"}
            style={style}
        >
            <span className="btn-title1">{title}</span>
            <span className="arrow1">{'>>'}</span>
        </div>
);

export default Button;