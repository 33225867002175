import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import SiteWrap from "../../Components/sitewrap";
import './style.scss';
import Button from '../../Components/button';
import Ticket from "../../Components/ticket";
import Arrow from '../../Assets/arrow.svg';
import { useLocation } from 'react-router-dom'
import moment from "moment";
import BA from "../../Assets/flightLogo/british_small.svg";

function ReturningFrom() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState(null);
    const [fromData, setFromData] = useState([]);
    const handleChange = (e) => {
    }
    const handleBack = () => {
        navigate(-1);
    }

    const handleNext = (item) => {
        // navigate('/returning-to', {
        //     state: {
        //         data: selectedItem.data
        //     }
        // })
        navigate('/returning-to', {
            state: {
                data: item?.data
            }
        })
    }
    const renderLogo = (airline) => {
        try {
            if (airline === "BA") return BA;
            return require(`../../Assets/Logos/${airline}.png`);
        } catch (err) {
            console.log(err);
            return null;
        }
    }
    useEffect(()=>{
        if(location?.state?.data) {
            const groupedData = location.state.data.reduce((result, item) => {
                const category = item.departure.airport.iata;
                if (!result[category]) {
                    result[category] = [];
                }
                result[category].push(item);
                return result;
            }, {});
            let array = [];
            for (const key in groupedData) {
                let obj = {}
                obj.logo = key;
                obj.data = groupedData[key];
                array.push(obj);
            }
            setFromData(array)
            console.log('===>', array);
        }
    },[])
    console.log(location.state.data)
    return (
        <SiteWrap loading={false} className='flight' title={'Where are you returning from?'}>
            {
                fromData.map(c => (
                    <Ticket key={c.logo} onClick={() => handleNext(c)} selected={c.logo === selectedItem?.logo} big>
                        <div className="flight-fromItem">
                            <img src={renderLogo(c.data[0].airline.iata)} className="flight-fromItem-logo logo2" />
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                                <span className="flight-fromItem-title">{c.data[0].departure.airport.iata}</span>
                                <img src={Arrow} />
                                <span className="flight-fromItem-date">{moment(location.state.date).format('DD MMM YY')}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="flight-fromItem-location">{c.data[0].departure.airport.city}, {c.data[0].departure.airport.country}</span>
                                <span className="flight-fromItem-location">Returning On</span>
                            </div>
                        </div>
                    </Ticket>
                ))
            }
            <div className='footer'>
                <Button title="Back" onClick={handleBack} back />
                <Button title="Next" onClick={handleNext} disable={!selectedItem} />
            </div>

        </SiteWrap>
    )
}
export default ReturningFrom;